<!-- 下单选项 -->
<template>
  <div id="BetArea">
    <nav class="betNav">
      <p
        @click="tabChange(item, index)"
        v-for="(item, index) in navList"
        :key="index"
        :class="{ active: index === activeIndex }"
      >
        {{ item.name }}
      </p>
    </nav>
    <section class="cont">
      <div v-if="activeIndex + '' === '0'">
        <ul class="cg">
          <li
            @click="conventionalChange(item, index)"
            v-for="(item, index) in conventional"
            :key="index"
            class="common-item cg"
            :class="{
              checked: arrIncludes(item.id),
            }"
          >
            <span>{{ item.show_xize }}</span
            ><em>{{ item.peilv }}</em>
          </li>
        </ul>
      </div>
      <div v-if="activeIndex + '' === '1'">
        <ul class="ts">
          <li
            @click="conventionalChange(item, index)"
            v-for="(item, index) in special"
            :key="index"
            class="common-item ts"
            :class="{
              checked: arrIncludes(item.id),
            }"
          >
            <span>{{ item.show_xize }}</span
            ><em>{{ item.peilv }}</em>
          </li>
          <!-- <li class="common-item ts margin"><span>绿波</span><em>3.80</em></li>
          <li class="common-item ts"><span>蓝波</span><em>3.80</em></li>
          <li class="common-item ts margin">
            <span>豹子通选</span><em>98.00</em>
          </li>
          <li class="common-item ts"><span>顺子通选</span><em>16.34</em></li>
          <li class="common-item ts margin">
            <span>对子通选</span><em>3.63</em>
          </li> -->
        </ul>
      </div>
      <div v-if="activeIndex + '' === '2'">
        <ul class="num">
          <li
            @click="conventionalChange(item, index)"
            v-for="(item, index) in digital"
            :key="index"
            class="common-item num"
            :class="{
              checked: arrIncludes(item.id),
            }"
          >
            <span>{{ item.show_xize }}</span
            ><em>{{ item.peilv }}</em>
          </li>
          <!-- <li class="common-item num margin"><span>1</span><em>326.66</em></li>
          <li class="common-item num"><span>2</span><em>163.34</em></li> -->
        </ul>
      </div>
      <div v-if="activeIndex + '' === '3'">
        <ul class="gd">
          <li
            @click="conventionalChange(item, index)"
            v-for="(item, index) in highLow"
            :key="index"
            class="common-item gd"
            :class="{
              checked: arrIncludes(item.id),
            }"
          >
            <span>{{ item.show_xize }}</span
            ><em>{{ item.peilv }}</em>
          </li>
          <!-- <li class="common-item gd margin">
            <span>百十低</span><em>2.18</em>
          </li>
          <li class="common-item gd"><span>百个高</span><em>2.18</em></li>
          <li class="common-item gd margin">
            <span>百个低</span><em>2.18</em>
          </li>
          <li class="common-item gd"><span>十个高</span><em>2.18</em></li>
          <li class="common-item gd margin">
            <span>十个低</span><em>2.18</em>
          </li> -->
        </ul>
      </div>
    </section>
    <div class="mask" v-if="showMask">
      <div class="follow-box">
        <div class="follow-wrapper">
          <p class="title">{{ $t("trading.tzqr") }}</p>
          <div class="item">
            <span class="label">{{ $t("trading.ls") }}</span
            ><span class="value">{{ topnum }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t("trading.jypl") }}</span
            ><span class="value">{{ navList[activeIndex].name }}</span>
          </div>
          <div class="item bet-item">
            <span class="label">{{ $t("trading.tznr") }}</span
            ><span class="value bet-content">{{ jyCon }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t("trading.jine") }}</span
            ><span v-if="hallType + '' !== '3'" class="value amount"
              >{{ moneyShow }}{{ $t("common.CNY") }}</span
            >
            <span v-else class="value amount"
              >{{ money }}{{ $t("common.CNY") }}</span
            >
          </div>
          <div class="item btns">
            <button @click="close">{{ $t("common.cancel") }}</button
            ><button @click="orderConfirm">{{ $t("common.confirm") }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="betCont">
      <input
        type="tel"
        v-if="hallType + '' !== '3'"
        :placeholder="$t('trading.qsrdbje')"
        v-model="money"
        maxlength="10"
      />
      <div v-else class="com_input" @click="show = true">
        <span style="color: #999999" v-if="money === ''">{{
          $t("trading.qsrdbje")
        }}</span>
        <span v-else>{{ money }}</span>
      </div>
      <!-- <select v-else id="ed">
        <option v-for="(item, index) in edList" :key="index" :value="item">
          {{ item }}
        </option>
      </select> -->
      <van-action-sheet
        :cancel-text="$t('common.cancel')"
        v-model="show"
        :actions="edList"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
      />
      <button class="s_btn" @click="openMask">{{ $t("trading.gr") }}</button>
    </div>
  </div>
</template>

<script>
import { placeOrder, topData, getEd } from "@/api/investhome";
import { Toast } from "vant";
export default {
  name: "BetArea",
  props: {
    conventional: {
      type: Array,
      default: [],
    },
    special: {
      type: Array,
      default: [],
    },
    digital: {
      type: Array,
      default: [],
    },
    highLow: {
      type: Array,
      default: [],
    },
    product_id: {
      type: String | Number,
      required: true,
    },
    hall_id: {
      type: String | Number,
      required: true,
    },
    hallType: {
      type: String | Number,
      default: -1,
    },
    edList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      show: false,
      showMask: false,
      activeIndex: 0,
      navList: [
        {
          name: this.$t("trading.cg"),
        },
        {
          name: this.$t("trading.ts"),
        },
        // {
        //   name: this.$t("trading.sz"),
        // },
        // {
        //   name: this.$t("trading.heigt"),
        // },
      ],
      checkedItemList: [],
      checkedList: [],
      money: "",
      moneyShow: "",
      jyCon: "",
      topnum: "",
      productId: -1,
      actions: [],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(this.edList);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    let option = this.$route.query;
    if (option.product && option.detile) {
      // 接收参数
      this.productId = option.product;
      this.topDataShow();
    }
  },
  methods: {
    onCancel() {
      console.log("取消");
    },
    /* 选择操作 */
    onSelect(e) {
      console.log(e);
      this.money = e.name;
    },
    topDataShow() {
      topData({
        product_id: this.productId,
      }).then((res) => {
        this.topnum = res.data.num;
        console.log(this.topnum);
      });
    },
    /* 验证是否包含 */
    arrIncludes(id) {
      return this.checkedList.includes(id);
    },
    close() {
      this.showMask = false;
    },
    /* 交易内容处理 */
    arrname() {
      let conList = [];
      this.checkedItemList.forEach((item) => {
        conList.push(item.show_xize);
      });
      this.moneyShow = this.checkedItemList.length * this.money;
      this.jyCon = conList.join(",");
    },
    /* 点击购入 */
    openMask() {
      if (this.checkedList.length <= 0) {
        Toast(this.$t("trading.qxztznr"));
        return false;
      }
      if (this.money === "") {
        Toast(this.$t("trading.qtxje"));
        return false;
      }
      this.arrname();
      this.showMask = true;
    },
    /* 重置数据 */
    resetForm() {
      /* this.money = ""; */
      this.checkedList = [];
      this.checkedItemList = [];
    },
    /* 选项切换 */
    tabChange(row, index) {
      this.resetForm();
      this.activeIndex = index;
    },
    /* 选中 */
    conventionalChange(item, index) {
      /* item.checked = !item.checked
      this.conventionalActive = index; */
      let sgin = this.checkedList.indexOf(item.id);
      let sgin2 = this.checkedItemList.indexOf(item);
      if (sgin2 + "" !== "-1") {
        this.checkedItemList.splice(sgin2, 1);
      } else {
        this.checkedItemList.push(item);
      }
      if (sgin + "" !== "-1") {
        // 如果包含
        this.$nextTick(() => {
          this.checkedList.splice(sgin, 1);
        });
        return;
      } else {
        this.checkedList.push(item.id);
      }
    },
    /* 确认下单 */
    orderConfirm() {
      let len = this.checkedList.length;
      let dataMoney = this.money;
      if (this.hallType + "" !== "3") {
        dataMoney = this.money * len;
      }
      placeOrder({
        product_id: this.product_id,
        hall_id: this.hall_id, // 厅id
        play_id: this.checkedList.join(","),
        money: dataMoney,
      }).then((res) => {
        console.log(res);
        Toast.success(this.$t("m_history.tzcg"));
        this.resetForm();
        this.close();
        this.$emit("successBuy");
      });
    },
  },
};
</script>
<style scoped lang="less">
#BetArea {
  width: 100%;

  .betNav {
    width: 100%;
    height: 0.8rem;
    /* background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
    background: #2b2e43;
    display: flex;
    align-items: center;

    p {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #ffffff;
      font-size: 0.32rem;
      text-align: center;
      line-height: 0.5rem;
      border-right: 1px solid #ffffff;
      position: relative;
    }

    .active {
      color: #9fb0ff;
    }
    .active::after {
      content: "";
      position: absolute;
      left: 20%;
      bottom: -0.11rem;
      width: 60%;
      height: 0.08rem;
      background-color: #9fb0ff;
    }
  }
  /* 内容部分 */
  .cont {
    ul {
      display: grid;
      flex-wrap: wrap;
      text-align: center;
      padding: 0.4rem 0.3rem;
      justify-content: space-between;
      grid-row-gap: 0.2rem;

      li {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.24rem;
        background-color: #2b2e43;
        border-radius: 0.1rem;

        em {
          color: #9fb0ff;
          margin-top: 0.04rem;
        }
      }
    }
  }
  .cont ul.cg {
    grid-template-columns: repeat(4, 23.5%);
    grid-template-rows: repeat(3, 1rem);
  }
}

#BetArea .cont ul li.cg:nth-child(9),
#BetArea .cont ul li.cg:nth-child(10) {
  grid-column: span 2;
}

#BetArea .cont ul.ts {
  grid-template-columns: repeat(3, 32%);
  grid-template-rows: repeat(2, 1rem);
}

#BetArea .cont ul.num {
  grid-template-columns: repeat(5, 1.1rem);
  grid-template-rows: repeat(6, 1.1rem);
}

#BetArea .cont ul.gd {
  grid-template-columns: repeat(2, 48%);
}

#BetArea .cont ul li.gd {
  height: 1.2rem;
}

#BetArea .cont ul li.checked {
  background-color: #9fb0ff;
  color: #423e38;
}
#BetArea .cont ul li.checked em {
  color: #423e38;
}

#BetArea .betCont {
  width: 100%;
  display: flex;
  padding: 0.15rem 0.3rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  input {
    width: 4.5rem;
    height: 0.9rem;
    border: solid 1px #2979ff;
    border-radius: 0.1rem;
    background-color: #2b2e43;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem;
    box-sizing: border-box;
  }

  .com_input {
    width: 4.5rem;
    height: 0.9rem;
    display: flex;
    align-items: center;
    border: solid 1px #e2cba7;
    border-radius: 0.1rem;
    background-color: #373430;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem;
    box-sizing: border-box;
  }

  select {
    width: 4.5rem;
    height: 0.9rem;
    border: solid 1px #e2cba7;
    border-radius: 0.1rem;
    background-color: #373430;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem;
    box-sizing: border-box;
  }

  .s_btn {
    width: 2rem;
    height: 0.9rem;
    /* background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
    background-image: linear-gradient(90deg, #2948ff, #396afc);
    border-radius: 0.1rem;
    color: #ffffff;
    font-size: 0.36rem;
    border: none;
  }
}
/* 弹窗部分 */
#BetArea .mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;

  .follow-box {
    position: relative;

    .follow-wrapper {
      width: 5.9rem;
      height: auto;
      background-color: #ffffff;
      border-radius: 0.1rem;
      overflow: hidden;
      margin: 2.4rem auto 0;
      display: flex;
      flex-direction: column;

      p {
        color: #333333;
        height: 0.98rem;
        line-height: 0.98rem;
        text-align: center;
        font-size: 0.34rem;
        border-bottom: 1px solid #e5e5e5;
      }

      .item {
        color: #333333;
        display: flex;
        justify-content: space-between;
        height: 0.98rem;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        padding: 0 0.6rem;
        font-size: 0.3rem;
      }

      .bet-item {
        height: auto;
        min-height: 0.98rem;
      }

      .btns {
        border: none;
        padding: 0;
        cursor: pointer;

        button {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 0.98rem;
          border: none;
          text-align: center;
          background-color: #fff;
          font-size: 0.34rem;
          color: #333333;
        }
      }

      .btns button:last-child {
        /* background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
        background-image: linear-gradient(90deg, #2948ff, #396afc);
        color: #ffffff;
      }
    }
  }
}

#BetArea .mask .follow-box .follow-wrapper .bet-content {
  width: 3.5rem;
  text-align: right;
  word-break: break-all;
}

.van-action-sheet__item,
.van-action-sheet__cancel {
  color: #000;
  background-color: #ffffff;
}
</style>
<!-- 历史轮 -->
<template>
  <div id="History">
    <div class="title" @click="showOther">
      <!-- <p class="number">
        第<i>{{ historyList[0].rounds || "-" }}</i
        >轮
      </p> -->
      <p class="number" v-if="$i18n.locale == 'zh'">
          第<i>{{ historyList[0].rounds || "-" }}</i
        >轮
        </p>
        <p class="number" v-else>
          {{$t('records.lunshu')}}:<i>{{ historyList[0].rounds || "-" }}</i>
        </p>
      <p class="ball">
        <span>{{ historyList[0].onenum }}</span
        ><span>+</span><span>{{ historyList[0].twonum }}</span
        ><span>+</span><span>{{ historyList[0].threenum }}</span
        ><!----><span>=</span><span>{{ historyList[0].sumnum }}</span>
      </p>
      <p class="type">{{ historyList[0].content }}</p>
      <span
        class="iconfont"
        :class="[isShow ? 'icon-xiajiantou' : 'icon-shangjiantou']"
      ></span>
    </div>
    <ul class="slide" :class="{ animate: isShow }">
      <li class="title" v-for="(item, index) in historyList" :key="index">
        <p class="number" v-if="$i18n.locale == 'zh'">
          第<i>{{ item.rounds }}</i>轮
        </p>
        <p class="number" v-else>
          {{$t('records.lunshu')}}:<i>{{ item.rounds }}</i>
        </p>
        <p class="ball">
          <span>{{ item.onenum }}</span
          ><span>+</span><span>{{ item.twonum }}</span
          ><span>+</span><span>{{ item.threenum }}</span
          ><!----><span>=</span><span>{{ item.sumnum }}</span>
        </p>
        <p class="type">{{ item.content }}</p>
        <span class="iconfont"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HistoryLun",
  props: {
    historyList: {
      type: Array,
      default: [
        {
          content: "--",
          id: "-",
          onenum: "-",
          product_id: "-",
          rounds: "-",
          sumnum: "-",
          threenum: "-",
          twonum: "-",
        },
      ],
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    showOther() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
<style scoped lang="less">
#History {
  width: 100%;
  box-sizing: border-box;

  .title {
    width: 100%;
    display: flex;
    height: 0.8rem;
    padding: 0 0.2rem;
    background: #254B7C;
    align-items: center;
    box-sizing: border-box;

    p.number {
      flex: 1;

      i {
        color: #9fb0ff;
      }
    }

    p.ball {
      flex: 1;
      padding-left: 0.2rem;
    }

    p.type {
      width: 1.8rem;
    }

    .iconfont {
      width: 0.5rem;
      font-size: 0.3rem;
      color: #fff;
    }
  }
}

.slide {
  width: 100%;
  overflow: auto;
  height: 5.6rem;
  max-height: 0;
  -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;
}

.animate {
  max-height: 9999px;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  transition-delay: 0s;
}
</style>